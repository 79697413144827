import React from 'react';
import Box from '@material-ui/core/Box';

export default function NoConfig() {
  return (
    <div>
      <Box pb={2}>No Configuration Required</Box>
    </div>
  )
}
